@import '../../global/styles/lib';

$small-height: 27rem;
$medium-height: 35rem;
$large-height: 39rem;
$xlarge-height: 42rem;
$xxlarge-height: 53rem;


.hero-banners {
    @include full-bleed;
    aspect-ratio: 16/9;
    max-height: 68rem;
    margin-bottom: 1rem;

    &.empty {
        height: 0;
        margin-bottom: $block-padding + 4rem;
    } // &.empty

    &::before {
        content: "";
        @include absolute-full;
        background-color: $masc-blue-medium;
    } // &::before

    & > .hero-banner {
        opacity: 0;
        transition: opacity 3s ease-in-out;

        img {
            object-position: center !important;
        } // img

        &.active {
            opacity: 1;
        } // .active
    } // & > .hero-banner

    &.blog {
        height: 17rem;
        & > .hero-banner {
            height: 17rem;
        } // & > .hero-banner
    } // &.blog


} // .hero-banners